import React, { memo, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import { useMutation } from 'react-query';
import { Form, Divider } from 'antd';
import * as yup from 'yup';

import AppNotifications from './app-notifications';

import { ISettings, UserSettingsPushStatus, UpdateEmail, ResetPassword } from '../../../types/models/user-settings';
import { getSettings, editSettings, resetPassword, updateEmail } from '../../../apis/users/settings';
import { getUser } from '../../../state/selectors/auth';
import { IUser } from '../../../types/models/auth';
import { useResponsive } from '../../../hooks/use-responsive';
import { useQuery } from '../../../hooks/use-query';
import UserContent from '../../common/user-content';
import theme from '../../../theme';
import PageHeader, { PageHeaderProps } from '../../common/page-header';
import { ACCOUNT_SETTINGS_EMAIL_NOTIFICATIONS_LABELS, PASSWORD_CONFIG } from '../../../constants';
import { Select, FormElementChangeEvent, OptionProps, Password, Input } from '../../forms';
import Button from '../../button';
import validateWithSchema from '../../../utils/yup/validateWithSchema';
import { Modal } from '../..';

type AccountSettingsContainerProp = {
  title: PageHeaderProps['title'];
  breadcrumb?: PageHeaderProps['breadcrumb'];
  description?: PageHeaderProps['description'];
};

const ProfileText = styled.div`
  padding: 1em 0em 0em;
  font-size: 16px;
  color: #000000;
`;

const FormContainer = styled.div`
  max-width: 354px;
`;

const EmailNotificationsSelect = styled(Select)`
  max-width: 354px;
`;

export const ComponentTitle = styled.div`
  color: ${theme.colorsBlack};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding-bottom: 10px;
  margin-top: 32px;
  @media (max-width: ${theme.screenLgMax}) {
    width: 100%;
  }
`;

const DEFAULT_SELECT_LABEL = 'Select One';
const options: OptionProps[] = Array.from(ACCOUNT_SETTINGS_EMAIL_NOTIFICATIONS_LABELS).map(([value, label]) => ({
  label,
  value: value.toString(),
}));

const AccountSettings = memo(function AccountSettings({
  title,
  breadcrumb,
  description,
}: AccountSettingsContainerProp) {
  const userInfo: IUser | null = useSelector(getUser);
  const { lg } = useResponsive();
  const isMobile = !lg;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [notificationsSettings, setNotificationsSettings] = useState<ISettings>({} as ISettings);
  const [emailFormData, setEmailFormData] = useState<UpdateEmail>({ newEmail: userInfo?.email });
  const [emailFormErrors, setEmailFormErrors] = useState<Record<string, any>>({});
  const [emailFormLoading, setEmailFormLoading] = useState<boolean>(false);
  const [passwordFormData, setPasswordFormData] = useState<ResetPassword>({});
  const [passwordFormErrors, setPasswordFormErrors] = useState<Record<string, any>>({});
  const [passwordFormLoading, setPasswordFormLoading] = useState<boolean>(false);

  const emailValidationSchema = yup.object().shape({
    newEmail: yup
      .string()
      .notOneOf([userInfo?.email], 'New Email must be different')
      .required('Email Address is required')
      .email('Please enter a valid email'),
    currentPassword: yup.string().required('Current Password is required'),
  });

  const passwordValidationSchema = yup.object().shape({
    currentPassword: yup.string().required('Current Password is required'),
    newPassword: yup
      .string()
      .min(PASSWORD_CONFIG.MIN_LENGTH as number, PASSWORD_CONFIG.MIN_LENGTH_ERROR as string)
      .matches(PASSWORD_CONFIG.RULE as RegExp, PASSWORD_CONFIG.RULE_ERROR as string)
      .required(PASSWORD_CONFIG.REQUIRED_ERROR as string),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const userId = get(userInfo, 'id', '');

  const { mutateAsync: updateSettings, isLoading: isUpdating } = useMutation<ISettings, unknown, ISettings>(
    (settingsData) => editSettings(settingsData, userId)
  );

  const { isLoading, error } = useQuery(
    [getSettings.QUERY_KEY, userId],
    ({ queryKey }) => getSettings(queryKey[1] as string),
    {
      enabled: userId !== '',
      onSuccess: (data: ISettings) => {
        if (data) {
          setNotificationsSettings(data);
        }
      },
      onError: (err: any) => {
        setErrorMessage(err.message);
      },
    }
  );

  const setSettings = useCallback(
    async (newSettings: ISettings) => {
      if (userId) {
        try {
          const data = await updateSettings(newSettings);

          setNotificationsSettings(data);
        } catch (err) {
          console.error(`Error updating settings data, userId: ${userId}`, err);
        }
      }
    },
    [updateSettings, userId]
  );

  const handleSettingsUpdate = useCallback(
    (sectionName: keyof ISettings, propertyName: string, value: any) => {
      setNotificationsSettings((prevSettings) => {
        const newSettings = {
          ...prevSettings,
          [sectionName]: {
            ...(prevSettings?.[sectionName] || {}),
            [propertyName]: value,
          },
        };

        if (!isEqual(prevSettings, newSettings)) {
          setSettings(newSettings);
        }

        return newSettings;
      });
    },
    [setSettings]
  );

  const onSelectChange = useCallback(
    (sectionName: keyof ISettings, propertyName: string) =>
      ({ value }: FormElementChangeEvent) => {
        handleSettingsUpdate(sectionName, propertyName, value);
      },
    [handleSettingsUpdate]
  );

  const onSwitchChange = useCallback(
    (sectionName: keyof ISettings, propertyName: string, status: string) => {
      handleSettingsUpdate(sectionName, propertyName, status);
    },
    [handleSettingsUpdate]
  );

  const handleEmailSubmit = useCallback(async () => {
    setEmailFormLoading(true);

    if (emailFormData.newEmail) {
      emailFormData.newEmail = emailFormData.newEmail.toLowerCase();
    }

    const { errors } = await validateWithSchema(emailValidationSchema, false, emailFormData);
    setEmailFormErrors(errors);

    if (errors && Object.keys(errors).length === 0 && userInfo) {
      try {
        await updateEmail(emailFormData, userInfo.id);
        Modal.info({
          title: 'Email update',
          content: 'A confirmation email has been sent to your new address.',
        });
      } catch (err: any) {
        Modal.error({
          title: 'Email update error',
          content: err.message,
        });
      }
    }
    setEmailFormLoading(false);
  }, [emailFormData, userInfo, emailValidationSchema]);

  const handleEmailChange = useCallback(
    ({ name, value }: FormElementChangeEvent) => {
      if (name) {
        setEmailFormData((prevState) => ({ ...prevState, [name]: value }));
      }
    },
    [setEmailFormData]
  );

  const handlePasswordSubmit = useCallback(async () => {
    setPasswordFormLoading(true);

    const { errors } = await validateWithSchema(passwordValidationSchema, false, passwordFormData);
    setPasswordFormErrors(errors);

    if (errors && Object.keys(errors).length === 0 && userInfo) {
      try {
        await resetPassword(passwordFormData, userInfo.id);
        Modal.info({
          title: 'Password reset',
          content: 'Your password was successfully updated!',
        });
      } catch (err: any) {
        Modal.error({
          title: 'Password reset error',
          content: err.message,
        });
      }
    }
    setPasswordFormLoading(false);
  }, [passwordFormData, userInfo, passwordValidationSchema]);

  const handlePasswordChange = useCallback(
    ({ name, value }: FormElementChangeEvent) => {
      if (name) {
        setPasswordFormData((prevState) => ({ ...prevState, [name]: value }));
      }
    },
    [setPasswordFormData]
  );

  const isReady = userInfo && notificationsSettings && !isLoading;

  if (!isReady || error) {
    return null;
  }

  const { messageCenter, push } = notificationsSettings;
  const { messageCenterPush, realTimeChatPush } = push || {};

  return (
    <>
      <PageHeader title={title} breadcrumb={breadcrumb} description={description} />
      {error && <p>{errorMessage}</p>}
      <UserContent>
        {isMobile && (
          <AppNotifications
            isUpdating={isUpdating}
            messageCenterPush={messageCenterPush === UserSettingsPushStatus.ENABLED}
            realTimeChatPush={realTimeChatPush === UserSettingsPushStatus.ENABLED}
            onSwitchChange={onSwitchChange}
          />
        )}
        <FormContainer>
          <ComponentTitle>Email Notifications</ComponentTitle>
          <EmailNotificationsSelect
            showSearch
            value={messageCenter?.notificationFrequency}
            optionFilterProp="children"
            onChange={onSelectChange('messageCenter', 'notificationFrequency')}
            options={options}
            disabled={isUpdating}
            loading={isUpdating}
            placeholder={DEFAULT_SELECT_LABEL}
            filterOption={(input: string, option: any) =>
              option &&
              option.props !== null &&
              option.props.children !== null &&
              typeof option.props.children === 'string' &&
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </FormContainer>
        <Divider />
        <FormContainer>
          <ComponentTitle>Update Email</ComponentTitle>
          <Form name="updateEmail" onFinish={handleEmailSubmit} layout="vertical">
            <Form.Item
              label="Email"
              validateStatus={emailFormErrors['newEmail'] ? 'error' : ''}
              help={emailFormErrors['newEmail']?.message}
            >
              <Input name="newEmail" value={emailFormData.newEmail} onChange={handleEmailChange} />
            </Form.Item>
            <Form.Item
              label="Password"
              validateStatus={emailFormErrors['currentPassword'] ? 'error' : ''}
              help={emailFormErrors['currentPassword']?.message}
            >
              <Password name="currentPassword" placeholder="Current Password" onChange={handleEmailChange} />
            </Form.Item>
            <Button type="primary-blue" htmlType="submit" loading={emailFormLoading} block>
              Submit
            </Button>
          </Form>
        </FormContainer>
        <Divider />
        <FormContainer>
          <ComponentTitle>Reset password</ComponentTitle>
          <Form name="basic" onFinish={handlePasswordSubmit} layout={'vertical'}>
            <Form.Item
              label="Current Password"
              validateStatus={passwordFormErrors['currentPassword'] ? 'error' : ''}
              help={passwordFormErrors['currentPassword']?.message}
            >
              <Password name="currentPassword" placeholder="Current Password" onChange={handlePasswordChange} />
            </Form.Item>

            <Form.Item
              label="New Password"
              validateStatus={passwordFormErrors['newPassword'] ? 'error' : ''}
              help={passwordFormErrors['newPassword']?.message}
            >
              <Password name="newPassword" placeholder="New Password" onChange={handlePasswordChange} />

              {(!passwordFormErrors['newPassword'] || passwordFormErrors['newPassword'].type !== 'matches') && (
                <ProfileText>{PASSWORD_CONFIG.RULE_ERROR}</ProfileText>
              )}
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              validateStatus={passwordFormErrors['confirmPassword'] ? 'error' : ''}
              help={passwordFormErrors['confirmPassword']?.message}
            >
              <Password name="confirmPassword" placeholder="Confirm Password" onChange={handlePasswordChange} />
            </Form.Item>
            <Button type="primary-blue" htmlType="submit" loading={passwordFormLoading} block>
              Submit
            </Button>
          </Form>
        </FormContainer>
        <Divider />
      </UserContent>
    </>
  );
});

export default AccountSettings;
